import React, { useEffect, useState } from 'react';
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";

const gridItems = [
    { id: 1, title: 'Item 1', description: 'Description 1' },
    { id: 2, title: 'Item 2', description: 'Description 2' },
    { id: 3, title: 'Item 3', description: 'Description 3' },
    { id: 4, title: 'Item 4', description: 'Description 4' },
    { id: 5, title: 'Item 5', description: 'Description 5' },
    { id: 6, title: 'Item 6', description: 'Description 6' },
    { id: 7, title: 'Item 7', description: 'Description 7' },
    { id: 8, title: 'Item 8', description: 'Description 8' },
    { id: 9, title: 'Item 9', description: 'Description 9' },
    { id: 10, title: 'Item 10', description: 'Description 10' },
    { id: 11, title: 'Item 11', description: 'Description 11' },
    { id: 12, title: 'Item 12', description: 'Description 12' }
    // Add more items as needed
];

const IndexPage = () => {
    const [scrollIndex, setScrollIndex] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
            const scrollWidth = document.documentElement.scrollWidth - window.innerWidth;
            const totalColumns = 12;
            const visibleColumns = 6;

            const scrollRatio = scrollLeft / scrollWidth;
            const newScrollIndex = Math.round(scrollRatio * (totalColumns - visibleColumns));

            setScrollIndex(newScrollIndex);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <HcpLayout pageClass="test-page">
            <div className="centered-content">
                <div className="grid-container" style={{ overflowX: 'scroll' }}>
                    <div className="grid-wrapper" style={{ width: `${gridItems.length * (100 / 12)}%` }}>
                        {gridItems.map((item) => (
                            <div
                                key={item.id}
                                className={`grid-item ${item.id > scrollIndex && item.id <= scrollIndex + 6 ? 'visible' : ''}`}
                                style={{ width: `${100 / 12}%` }}
                            >
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </HcpLayout>
    );
};

export default IndexPage;

export const Head = () => <Seo title="Title" />;
